import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import fad from "../assets/fadrailbank.png";
import WalletApiService from "../../services/api/WalletApiService";
import { ToastContainer, toast } from "react-toastify";
import dele from "../assets/bin.png";
import LoaderComponent from "../utils/LoaderComponent";
import EmptyContainer from "../utils/EmptyContainer";
import Span from "../Translation/Translate";
import { BasicServices } from "../../services/BasicServices";
import { BsBank2 } from "react-icons/bs";
const Addbank = () => {
  const Navigate = useNavigate();
  const walletService = new WalletApiService();
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt
  function reloadBanks() {
    setIsLoading(true);
    walletService
      .getAllBanks()
      .then((res) => {
        if (res?.status === 1) {
          console.log("all banks ", res);
          setBanks(res.banks);
        } else if (res.status === "TOKEN_ERR") {
          // localStorage.removeItem("api_url");
                    basic.setJwt("")

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } 
        
        else {
          toast(res.Backend_Error);
        }
      })
      .catch((err) => toast(err.message))
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    reloadBanks();
  }, []);

  function deleteBank(acc_id) {
    walletService
      .deleteBank(acc_id)
      .then((res) => {
        if (res?.status === 1) {
          let cpyArr = [...banks];
          cpyArr = cpyArr.filter((element) => element._id !== acc_id);
          setBanks(cpyArr);
          toast("Bank removed Succesfully");
        } else if (res.status === "TOKEN_ERR") {
           // localStorage.removeItem("api_url");
                    basic.setJwt("")

          Navigate("/Register");
          toast("Logged in other device! Please login again");
        } else {
          toast(res.Backend_Error);
        }
      })
      .catch((error) => {
        toast(error.message);
      });
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div>
          <div className="" style={{ height: "10vh" }}>
            <div className="container">
              <div className="row mt-2 pt-4">
                <div className="col-3 d-flex justify-content-between">
                  <IoMdArrowRoundBack
                    onClick={() => Navigate(-1)}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  />
                </div>
                <div className="col-8 d-flex justify-content-end">
                  <div className="">
                    <h6
                      className="p-2"
                      onClick={() => {
                        Navigate("/wallet/bankfo");
                      }}
                      style={{
                        fontSize: 15,
                        backgroundColor: "#EDF7FF",
                        padding: "8px 17px",
                        border: "1px solid #2188E7",
                        borderRadius: 10,
                        color: "#2188E7",
                        cursor: "pointer",
                      }}
                    >
                      <Span>+Add</Span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="row">
                    <div
                      className="pt-3 example"
                      style={{ height: "78vh", overflowY: "auto" }}
                    >
                      <section>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-6 col-sm-12">
                              <div className="row">
                                {isLoading ? (
                                  <LoaderComponent />
                                ) : banks.length <= 0 ? (
                                  <EmptyContainer
                                    message={"No Banks Added Yet"}
                                    action={reloadBanks}
                                    actionName={"Reload"}
                                  />
                                ) : (
                                  banks.map((res) => {
                                    return (
                                      <div className="col-12 pb-3">
                                        <div
                                          className="border 1px solid p-2"
                                          style={{ borderRadius: "5px" }}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex justify-content-start">
                                              <BsBank2
                                              className="pe-2"
                                                 style={{ width: "40px", height:"36px" }}

                                              />
                                              <h6
                                                className="pt-1"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "25px",
                                                }}
                                              >
                                                <Span>{res?.bank_name}</Span>
                                              </h6>
                                            </div>

                                            <button
                                              type="button"
                                              class="btn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#exampleModal"
                                              style={{ border: "none" }}
                                            >
                                              <div
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              >
                                                <img
                                                  src={dele}
                                                  alt="delete"
                                                  style={{
                                                    width: "100%",
                                                    backgroundSize: "cover",
                                                  }}
                                                />
                                              </div>
                                            </button>

                                            <div
                                              class="modal fade"
                                              id="exampleModal"
                                              tabindex="-1"
                                              aria-labelledby="exampleModalLabel"
                                              aria-hidden="true"
                                            >
                                              <div class="modal-dialog">
                                                <div class="modal-content">
                                                  <div class="modal-header">
                                                    <h1
                                                      class="modal-title fs-5"
                                                      id="exampleModalLabel"
                                                    >
                                                      <Span>
                                                        Do you want to delete
                                                        your account
                                                      </Span>
                                                    </h1>
                                                  </div>

                                                  <div class="modal-footer d-flex justify-content-center align-items-center">
                                                    <button
                                                      type="button"
                                                      class="btn btn-secondary"
                                                      data-bs-dismiss="modal"
                                                    >
                                                      <Span>No</Span>
                                                    </button>
                                                    <button
                                                      type="button"
                                                      data-bs-dismiss="modal"
                                                      onClick={() => {
                                                        deleteBank(res._id);
                                                      }}
                                                      class="btn btn-primary"
                                                    >
                                                      <Span>Yes</Span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <h6
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            <span style={{ color: "#7E7E7E" }}>
                                              Acc. Holder{" "}
                                            </span>
                                            <Span>{res.acc_holder_name}</Span>
                                          </h6>
                                          <div className="d-flex justify-content-between">
                                            <h6
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#7E7E7E" }}
                                              >
                                                <Span> Account Number</Span>{" "}
                                              </span>{" "}
                                              <Span>{res.bank_acc_no}</Span>
                                            </h6>
                                            <h6
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{ color: "#7E7E7E" }}
                                              >
                                                <Span> IFSC</Span>{" "}
                                              </span>
                                              <Span>{res.ifsc_code}</Span>
                                            </h6>
                                          </div>
                                          <div className="d-flex justify-content-between">
                                            {res.is_verifed === 0 ? (
                                              <p>
                                                <Span>Not Verfied Yet</Span>
                                              </p>
                                            ) : (
                                              <p>
                                                <Span>Verfied Bank</Span>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Addbank;
