import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ChatWindow from './ChatWindow';
import InputBox from '../ChatBot/InputBox';
import logo from '../assets/bb2.png';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { IoHomeSharp } from "react-icons/io5";

const ChatbotContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
`;

const Logo = styled.img`
  height: 50px; // Adjust the height as per your logo size
`;

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [buttons, setButtons] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchGreeting = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "sender": "test",
        "message": "/greet"
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
        const response = await fetch("https://chatbot.brainbucks.in/webhooks/rest/webhook", requestOptions);
        const result = await response.json();
        if (Array.isArray(result)) {
          const greetingMessage = result[0];
          setMessages([{ text: greetingMessage.text, sender: "bot" }]);
          setButtons(greetingMessage.buttons || []);
        } else {
          setMessages([{ text: "Sorry, I didn't understand that.", sender: "bot" }]);
        }
      } catch (error) {
        console.error(error);
        setMessages([{ text: "There was an error connecting to the server.", sender: "bot" }]);
      }
    };

    fetchGreeting();
  }, []);

  const handleButtonClick = async (button) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "sender": "test",
      "message": button.payload
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://chatbot.brainbucks.in/webhooks/rest/webhook", requestOptions);
      const result = await response.json();
      if (Array.isArray(result)) {
        const responseMessages = result.map(res => ({ text: res.text, sender: "bot" }));
        const newButtons = result[0].buttons || [];
        setMessages(prevMessages => [...prevMessages, { text: button.title, sender: "user" }, ...responseMessages]);
        setButtons(newButtons);
      } else {
        setMessages(prevMessages => [...prevMessages, { text: "Sorry, I didn't understand that.", sender: "bot" }]);
        setButtons([]);
      }
    } catch (error) {
      console.error(error);
      setMessages(prevMessages => [...prevMessages, { text: "There was an error connecting to the server.", sender: "bot" }]);
      setButtons([]);
    }
  };

  const sendMessage = (message) => {
    setMessages([...messages, { text: message, sender: "user" }]);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "sender": "test",
      "message": message
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://chatbot.brainbucks.in/webhooks/rest/webhook?user=message", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (Array.isArray(result)) {
          const botMessages = result.map(res => ({ text: res.text, sender: "bot" }));
          setMessages(prevMessages => [...prevMessages, ...botMessages]);
        } else {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: "Sorry, I didn't understand that.", sender: "bot" }
          ]);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: "There was an error connecting to the server.", sender: "bot" }
        ]);
      });
  };

  return (
    <ChatbotContainer>
      <IoHomeSharp style={{
          position: "absolute",
          left: "15px",
          fontSize: "24px",
          color: "white",
          cursor: "pointer",
          top: "16px"
          }}
          onClick={() => {Navigate("/")}}  
       />
      <Header style={{backgroundColor:"#6f42c1"}}>
        <Logo src={logo} alt="Logo" />
        <IoMdClose  style={{
          position: "absolute",
          right: "10px",
          fontSize: "30px",
          color: "white",
          cursor: "pointer",
          top:"15px"
          }}
          onClick={() => {Navigate("/")}}
          />
      </Header>
      <ChatWindow messages={messages} buttons={buttons} onButtonClick={handleButtonClick} />
      <InputBox onSendMessage={sendMessage} /> {/* onSendMessage does nothing for now */}
    </ChatbotContainer>
  );
};

export default Chatbot;