import React, { useContext, useState } from "react";
import "../Rooms/Rooms.css";
import Bottom from "../Home/Bottom";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Explore from "./Explore";
import MyRooms from "./MyRooms";
import myroomtab from "../assets/icons/myroomtab.svg";
import { useNavigate } from "react-router-dom";
import Private from "./Private";
import { Context } from "../..";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { AUTHMICRO } from "../../config/urls";
import { toast } from "react-toastify";
import { BasicServices } from "../../services/BasicServices";
import { Spinner } from "react-bootstrap";
import Congratulations from "../assets/Congratulations.json";
import Lottie from "lottie-react";


const Rooms = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setOtp(Array(6).fill("")); 
    setPublicEmail("");
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const { value, setValue } = useContext(Context);
  const {  settab } = useContext(Context);
  const [otp, setOtp] = useState(Array(6).fill("")); // Array to store each OTP digit
  const [publicEmail, setPublicEmail] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showLottie, setShowLottie] = useState(false);
  const enteredOtp = otp.join("");
  

  const basic = new BasicServices();
  const token = basic.getLocalObject().jwt;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async () => {
    if (step === 1) {
      // Call applyOtp API after submitting email
      await applyOtp();
    } else if (step === 2) {
      VerifyOtp();
      
     
    }
  };

  const handleOtp = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input box if a digit was entered
      if (value !== "" && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  // api for apply public room
  const applyPublic = async () => {
    setApplyLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,

      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${AUTHMICRO}/auth/room/client/verify/for/public/room/eligiblity/otp`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        setStep(1); // Show email input
        handleShow();
      } else {
        toast(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setApplyLoading(false);
    }
  };


  // api for to get otp 
  const applyOtp = async () => {
    setEmailLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "email": publicEmail
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${AUTHMICRO}/auth/room/client/send/otp/to/mail/for/registor`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        setStep(2); // Show OTP input
      } else {
        toast(result?.Backend_Error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setEmailLoading(false);
    }

  };

   // api for to verify otp 
  const VerifyOtp = async () => {
    setEmailLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "email": publicEmail,
      "otp": enteredOtp,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${AUTHMICRO}/auth/room/client/verify/otp/to/mail/for/registor`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status == 1) {
        handleClose();
        toast(result?.message);
        setShowLottie(true);
        setTimeout(() => {
          setShowLottie(false);
        }, 3000);
        

      } else {
        toast(result?.Backend_Error);
       
        
      }
    } catch (error) {
      console.error(error);
    } finally {
      setEmailLoading(false);
    }

  };



  return (
    <>
      <div
        className="overflow-hidden"
        style={{ height: "100vh", width: "100vw" }}
      >
        {/* top bar */}
        <div className="top_room px-3 ">
          <div className="rom_ncreate mt-4 d-flex justify-content-between">
            <div className="">
              <h3>Rooms</h3>
            </div>
            <div className="d-flex">
              <button
                type="button"
                class="btn btn-primary me-2"
                onClick={applyPublic}
              >
                {applyLoading ? <Spinner />  : "Apply for Public"}
              </button>

              <button
                type="button"
                class="btn btn-primary ms-2"
                onClick={() => Navigate("/createnewroom")}
              >
                + Create Room
              </button>
            </div>
          </div>


          {showLottie ? (
            <div className="center-data ">
            <Lottie
              animationData={Congratulations}
              autoPlay={true}
              style={{ width: 400, height: 400 }}
            />
          </div>
          ) : (
<div className="">
            <Box
              className="w-100 my-2 mx-md-2"
              sx={{
                borderColor: "divider",
                borderBottom: "1px solid #E4E9EC",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Explore"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
                <Tab
                  label="Private"
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />

                <Tab
                  label={
                    <div onClick={() => settab(1)}>
                      <img className="pe-2" src={myroomtab} alt="myrooms" />
                      My Rooms
                      {/* <span className='p-1' style={{ color: "white", backgroundColor: "#D92828", borderRadius: "50%" }}>9+</span> */}
                    </div>
                  }
                  style={{
                    color: "#8A8A8A",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                />
              </Tabs>
            </Box>

            {value === 0 && <Explore />}
            {value === 1 && <Private />}
            {value === 2 && <MyRooms />}
          </div>
          )}
          


        </div>
        {/* bootombar */}
        {/* Bottom Section */}

      
          <div
          style={{
            flex: "0 0 auto",
            backgroundColor: "#6f42c1",
            padding: "7px",
            paddingTop: 0,
            color: "white",
          }}
        >
          <Bottom />
        </div>
         
        

        {/* Model start for apply for Public */}
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply for Public Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={publicEmail}
                onChange={(e) => setPublicEmail(e.target.value)}
              />
            </Form.Group>
          )}
          {step === 2 && (
            <div>
              <Form.Label>Enter OTP</Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "60%",
                  margin: "0 auto",
                }}
              >
                {otp.map((digit, index) => (
                  <Form.Control
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleOtp(e, index)}
                    maxLength="1"
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {emailLoading ? <Spinner /> : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  );
};

export default Rooms;
