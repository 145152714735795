import React, { useRef, useState, useEffect } from "react";
import "./reel.css";
import { NOTIFY_URL } from "../../config/urls";

function Reel({ short }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(videoRef.current.muted);

    const handleBlur = () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    const handleFocus = () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  const handleVideoError = () => {
    console.error("Error loading video:", short.blobName);
     // Handle video error, e.g., set placeholder image
    videoRef.current.poster = "path_to_placeholder_image.jpg";
  };

  return (
    <div className="reel">
      <div className="reel-video">
        <div className="video">
          <video
            ref={videoRef}
            onClick={togglePlay}
            disableRemotePlayback
            playsInline
            loop
            src={`${NOTIFY_URL}${short.blobName}`}
            onError={handleVideoError}
          ></video>
          <div className="controls">
            <span onClick={togglePlay}>
              <ion-icon name={`${isPlaying ? "pause" : "play"}-outline`}></ion-icon>
            </span>
            <span onClick={toggleMute}>
              <ion-icon name={`volume-${isMuted ? "mute" : "medium"}-outline`}></ion-icon>
            </span>
          </div>
          <div className="foot">
            <div className="title">{short.name}</div>
            {/* Add additional UI elements as needed */}
          </div>
        </div>
      </div>
    </div>
  );
}

function isActiveVideo(videoRef) {
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= window.innerHeight - 150;
}

export default Reel;
